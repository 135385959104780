import React from "react"

import Layout from "../layouts/de"
import Seo from "../components/seo"
import SimplePage from "../templates/simple-page"

const NotFoundPage = () => (
    <Layout>
        <Seo title="Nicht gefunden" />
        <SimplePage title="Nicht gefunden">
            <p>Der gesuchte Inhalt konnte leider nicht gefunden werden.</p>
        </SimplePage>
    </Layout>
)

export default NotFoundPage
